(function () {
    var home = function () {
        this.qrContainer = $('#qr-container');
        this.siteid = '1254909544';
    };

    home.prototype.init = function () {
        this.bindEvents();
        function getUrlParam(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
            var r = window.location.search.substr(1).match(reg);  //匹配目标参数
            if (r != null) return unescape(r[2]); return null; //返回参数值
        }
        var _czc = this._czc = window._czc || [];
        if (_czc) {
            _czc.push(["_setAccount", this.siteid]);
            var refsrc = getUrlParam('refsrc') || 'none';
            _czc.push(["_trackEvent", refsrc, 'loaded', '', 0, 'body']);
        }
    };

    home.prototype.bindEvents = function () {
        var self = this, hasLoaded;
        $(document).ready(function () {
            var scriptTag = document.createElement('script');
            scriptTag.src = '//cb.cdn.changbaimg.com/js/widget/dialog.js?ver=1.0.1';
            var linkTag = document.createElement('link');
            linkTag.href = '//cb.cdn.changbaimg.com/css/widget/dialog.css';
            var auditTag = document.createElement('script');
            auditTag.src = '//s95.cnzz.com/stat.php?id=1254909544&web_id=1254909544';
            linkTag.setAttribute('type', 'text/css');
            linkTag.setAttribute('rel', 'stylesheet');
            $('head').append(scriptTag).append(linkTag).append(auditTag);
        });

        $('.home-qr-icon').on('click', function () {
            if (!hasLoaded) {
                self.qrContainer.dialog({ 'title': '扫描二维码下载唱吧' });
                hasLoaded = true;
            }
            self.qrContainer.dialog('show');
        });

        $('.home-download-link').on('click', function () {
            var scriptTag = document.createElement('script');
            scriptTag.src = '//s4.cnzz.com/stat.php?id=1257372975&web_id=1257372975';
            $('head').append(scriptTag);

            var body = document.body, scrollHeight = body.scrollHeight - document.documentElement.clientHeight, ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf('msie 8') >= 0 || ua.indexOf('msie 7') >= 0 || ua.indexOf('msie 6') >= 0 || document.documentMode) {
                var intervalId = window.setInterval(function () {
                    document.documentElement.scrollTop = document.documentElement.scrollTop + 70;
                    if (document.documentElement.scrollTop >= scrollHeight) {
                        clearInterval(intervalId);
                    }
                }, 30);
            }
            else {
                $(body).animate({ scrollTop: document.body.scrollHeight - document.documentElement.clientHeight });
            }
        });

        $('#ios_link').on('click', function () {
            var scriptTag = document.createElement('script');
            scriptTag.src = '//s11.cnzz.com/stat.php?id=1257363475&web_id=1257363475';
            $('head').append(scriptTag);
        });

        $('#android_link').on('click', function () {
            var scriptTag = document.createElement('script');
            scriptTag.src = '//s95.cnzz.com/stat.php?id=1257363463&web_id=1257363463';
            $('head').append(scriptTag);
        });

        $('#global-home-banner').on('click', function () {
            _czc.push(["_trackEvent", 'banner', 'click', '', 0, 'body']);
        });

        $('.js-slick').on('init', function () {
            $('.js-slick-item').show();
        }).slick({
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: false,
            dots: true
        });
    };

    (new home()).init();
})();
